import baseComponent from "@/scripts/baseComponent";
import authApi from '@/api/common/auth';

export default {

    extends: baseComponent,

    props: {

    },

    data() {
        return {

        };
    },

    computed: {

    },

    created() {

    },

    mounted() {

    },

    methods: {

        // Close this pop-up
        closeThisPopup() {
            this.listData = {};
            this.closePopup('system-settings-policy-promotion-deposit');
        },
    }
} 